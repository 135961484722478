import { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import { ReactComponent as SVGstack } from "../../assets/Images/Logo Manipulable.svg"; // Importar SVG

gsap.registerPlugin(ScrollTrigger);

export default function Encabezado() {
  useEffect(() => {
    // Configura el estado inicial del logo
    gsap.set("#LogoCabecera", { yPercent: -110, x: 20, fill: "white" }); // Empieza en -100%

    // Entrada del Logo
    gsap.to("#LogoCabecera", {
      yPercent: 20, // Termina en 0%
      ease: "none",
      scrollTrigger: {
        trigger: "#LogoCabecera",
        start: "800 top", // Ajusta según sea necesario
        end: "+=1 top", // Ajusta según sea necesario
        scrub: 1,
        // markers: true,
      },
    });

    // Cambiar el color del logo al hacer scroll
    gsap.to("#LogoCabecera", {
      fill: "black", // Cambia a tu color deseado
      scrollTrigger: {
        trigger: "#LogoCabecera",
        start: "2000 top", // Ajusta según sea necesario
        end: "+=1 top", // Ajusta según sea necesario
        scrub: 1,
        // markers: true,
      },
    });
  }, []);

  return (
    <SVGstack
      id="LogoCabecera" // Asegúrate de añadir el ID para que GSAP pueda seleccionar el elemento
      className="fixed top-0 left-0 w-[10vw] h-auto z-50"
    />
  );
}
