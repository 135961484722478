import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin"; // Importa el plugin

import { useEffect } from "react";

import diseño_web from '../../assets/Images/Services/diseño_web.svg';
import desarrollo_web from '../../assets/Images/Services/desarrollo_web.svg';
import mantenimiento_web from '../../assets/Images/Services/mantenimiento_web.svg';

export default function Services() {

    useEffect(() => {

        let ItemCards = document.querySelectorAll('.Cards');
        let containerServiceText = document.querySelectorAll('.title_service');
        let contentServices = document.querySelectorAll('.content_service > p');
        let IconContainerImage = document.querySelectorAll('.icon');
        let ImageService = document.querySelectorAll('.icon > img');

        gsap.registerPlugin(ScrollTrigger, ScrollToPlugin); // Registra ambos plugins

        // Creación de la línea de tiempo con ScrollTrigger
        const timeline = gsap.timeline({
            scrollTrigger: {
                trigger: '.nuestros-servicios',
                start: 'top center',
                end: '+=500 center',
                // markers: true,
                scrub: 1,
            },
            // Hacer que la animación retroceda después de completarse
            yoyo: true,
            repeat: 1,
            repeatDelay: 1, // Retraso de 2 segundos antes de que retroceda
        });
        
        // Animación hacia opacidad 1 y luego de regreso a opacidad 0
        timeline.fromTo(
            '.nuestros-servicios',
            { opacity: 0, y: 100 },
            { opacity: 1, y: 0, duration: 0.3 }
        );

        gsap.from('.services-content', {
            scrollTrigger: {
                trigger: '.services-content',
                start: 'top top',
                end: () => `+=2000`,
                // markers: true,
                pin: true,
            },
        })

        gsap.fromTo('.CardsContainer', {
            y: 100,
            opacity: 0,
        }, {
            y: 0,
            opacity: 1,
            duration: 0.5,
            scrollTrigger: {
                trigger: '.CardsContainer',
                start: 'top 80%',
                end: '+=500 bottom',
                // markers: true,
                scrub: 1
            },
        })

        ItemCards.forEach((card, index) => {
            gsap.fromTo(
                card,
                {
                    width: '10vw', // Estado inicial de la animación
                    height: '30vh',
                },
                {
                    width: '20vw', // Estado final de la animación
                    height: '40vh',
                    duration: 0.5,
                    ease: "power2.inOut", // Suavizado para una transición más suave
                    scrollTrigger: {
                        trigger: card, // El contenedor que activa la animación
                        start: `top ${50 - index * 50}%`, // Desfase progresivo para cada .Cards
                        end: '+=200', // Longitud de la animación
                        scrub: 0,
                        // markers: true, // Puedes quitar esto si no necesitas los marcadores
                        onEnter: () => {
                            IconContainerImage[index].style.width = '50%';
                            IconContainerImage[index].style.height = '50%';
                            ImageService[index].style.width = '100%';
                            containerServiceText[index].style.width = '100%'
                            // Mostrar el contenido de la tarjeta actual
                            setTimeout(() => {
                                contentServices[index].classList.remove('hiddden');
                                contentServices[index].classList.add('vissible');
                            }, 500);
        
                            // Reducir las otras tarjetas
                            ItemCards.forEach((otherCard, otherIndex) => {
                                if (otherIndex !== index) {
                                    gsap.to(otherCard, {
                                        width: '10vw',
                                        height: '30vh',
                                        duration: 0.5,
                                        ease: "power2.inOut",
                                        onEnter: () => {
                                            contentServices[otherIndex].classList.remove('vissible');
                                            contentServices[otherIndex].classList.add('hiddden');
                                        }
                                    });
                                }
                            });
                        },
                        
                        onLeaveBack: () => {
                            // Cerrar la tarjeta actual cuando se regresa en el scroll
                            gsap.to(card, {
                                width: '10vw',
                                height: '30vh',
                                duration: 0.5,
                                ease: "power2.inOut",
                                onEnter: () => {
                                    contentServices[index].classList.remove('vissible');
                                    contentServices[index].classList.add('hiddden');
                                }
                            });

                            // Abrir la tarjeta anterior de forma suave
                            if (index > 0) {
                                gsap.to(ItemCards[index - 1], {
                                    width: '20vw',
                                    height: '40vh',
                                    duration: 0.5,
                                    ease: "power2.inOut",
                                    onStart: () => {
                                        setTimeout(() => {

                                            contentServices[index - 1].classList.remove('hiddden');
                                            contentServices[index - 1].classList.add('vissible');
                                        }, 500);
                                    }
                                });
                            }
                        },
                    }
                }
            );
        });
                
        
    });

    return (
        <section>
            <div className="services">
                <div className="services-title flex justify-center min-h-[200vh] w-full">
                    <div className="sticky top-0 max-h-[100vh] flex items-center">
                        <h2 className="nuestros-servicios font-semibold text-[4rem]" >
                            Nuestros Servicios
                        </h2>
                    </div>
                </div>

                <div className="services-content px-3 flex items-center h-screen">
                    <div className="CardsContainer flex flex-row items-center w-full max-w-[1300px] m-auto justify-center gap-8">
                        <div className="Cards Diseño-Web w-[10vw] flex flex-col items-center h-[30vh] justify-end bg-slate-700 p-8 rounded-xl">
                            <div className="icon">
                                <img src={diseño_web} alt="" />
                            </div>
                            <div className="title_service">
                                <h3 className="flex items-center h-[30px] mt-5">Diseño Web</h3>
                            </div>
                            <div className="content_service">
                                <p className="hiddden mt-5">
                                    Creamos lugares en internet donde tu marca cobra vida de manera auténtica.
                                </p>
                            </div>
                        </div>
                        <div className="Cards Desarrollo-web w-[10vw] flex flex-col items-center h-[30vh] justify-end bg-slate-700 p-8 rounded-xl">
                            <div className="icon">
                                <img src={desarrollo_web} alt="" />
                            </div>
                            <div className="title_service">
                                <h3 className="flex items-center h-[30px] mt-5">Desarrollo web</h3>
                            </div>
                            <div className="content_service">
                                <p className="hiddden mt-5">
                                    desarrollamos experiencias digitales que hacen la diferencia.
                                </p>
                            </div>
                        </div>
                        <div className="Cards Mantenimiento-web w-[10vw] flex flex-col items-center h-[30vh] justify-end bg-slate-700 p-8 rounded-xl">
                            <div className="icon">
                                <img src={mantenimiento_web} alt="" />
                            </div>
                            <div className="title_service">
                                <h3 className="flex items-center h-[30px] mt-5">Mantenimiento web</h3>
                            </div>
                            <div className="content_service">
                                <p className="hiddden mt-5">
                                    Cuidamos de tu sitio web para que tú no tengas que preocuparte.
                                </p>
                            </div>
                        </div>
                        {/* <div></div>
                        <div></div>
                        <div></div> */}
                    </div>
                </div>
            </div>
        </section>
    )
}