import { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default function Mouse() {
  useEffect(() => {
    // Animacion del Mouse
    gsap.fromTo(
      "#mouse", // Selecciona el elemento que quieres animar
      { opacity: 0 }, // Estado inicial
      {
        opacity: .7, // Estado final
        duration: 1, // Duración del primer fade in
        delay: 3, // Espera 2 segundos antes de comenzar la animación
        onComplete: function () {
          gsap.to("#mouse", {
            opacity: 0.2,
            duration: 1, // Duración del yoyo
            repeat: -1, // Repetir infinitamente
            yoyo: true, // Hacer el efecto de yoyo
          });
        },
      }
    );

    // Cambio de color del Mouse
    gsap.set("#mouse", { fill: "white" });
    gsap.to("#mouse", {
      fill: "black", // Cambia a tu color deseado
      duration: 1, // Duración de la animación
      scrollTrigger: {
        trigger: ".animacion", // El elemento que activa la animación
        start: "1900 top",
        end: "+=400 top", // Cuando se alcanza el final del elemento
        scrub: 1,
        // markers: true,
      },
    });

    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);

  return (
    <div className="fixed bottom-0 right-1/2 translate-x-1/2 mb-4 z-50">
      <svg
        id="mouse"
        xmlns="http://www.w3.org/2000/svg"
        height="50"
        viewBox="0 0 24 24"
      >
        <g>
          <path d="M12 5a1 1 0 0 0-1 1v4a1 1 0 1 0 2 0V6a1 1 0 0 0-1-1" />
          <path
            fill-rule="evenodd"
            d="M4 8a8 8 0 1 1 16 0v8a8 8 0 1 1-16 0zm14 0v8a6 6 0 0 1-12 0V8a6 6 0 1 1 12 0"
            clip-rule="evenodd"
          />
        </g>
      </svg>
    </div>
  );
}
