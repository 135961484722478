import { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default function Parrafo() {
  useEffect(() => {
    const section2 = document.getElementById("seccionParrafo");
    const content2 = section2.querySelector(".content2");
    const adjustedWidth2 = content2.offsetWidth - window.innerWidth / 2;

    const splitTypes = document.querySelectorAll(".split2");

    const duration = 6000;

    // El pin de la seccion
    gsap.to(section2, {
      ease: "none",
      scrollTrigger: {
        trigger: section2,
        start: "top top",
        pin: true,
        end: () => `+=${duration}`,
        // markers: true,
      },
    });

    // Efecto en Texto
    splitTypes.forEach((char, i) => {
      // eslint-disable-next-line no-undef
      const split = new SplitType(char, { type: "chars" });
      // eslint-disable-next-line no-undef
      const splitChars = split.chars;

      gsap.from(splitChars, {
        opacity: 0,
        stagger: 0.1,
        color: "#ffffff",
        scrollTrigger: {
          trigger: section2,
          start: "top bottom",
          end: () => `+=5000 bottom`,
          // markers: 1,
          scrub: 1,
        },
      });
    });

    // Desplazamiento horizontal
    gsap.to(content2, {
      x: -adjustedWidth2,
      ease: "none",
      scrollTrigger: {
        trigger: "#textoId",
        start: "center top",
        scrub: 2,
        end: () => `+=${duration / 1.5} bottom`,
        // markers: true,
      },
    });

    // Cambia opacidad al terminar
    const tittle = section2.querySelector("#textoId");
    gsap.to(tittle, {
      opacity: 0,
      scrollTrigger: {
        trigger: "#textoId",
        start: `5500 center`, // Iniciar justo después del scroll horizontal
        scrub: 1,
        end: "+=1", // Duración del efecto
        // markers: true,
      },
    });



    // gsap.to(tittle, {
    //   scrollTrigger: {
    //     trigger: section2,
    //     start: `700 center`, // Iniciar justo después del scroll horizontal
    //     end: "+=1", // Duración del efecto
    //     markers: true,
    //     onLeave: () => gsap.to(window, { duration: 1, scrollTo: ".services" }), // Desplaza al inicio de la animación
    //   },
    // });


    // Limpiar los ScrollTriggers y eventos cuando el componente se desmonte
    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);

  return (
    <section
      id="seccionParrafo"
      className="flex items-center overflow-hidden select-none"
    >
      <div className="flex h-[100vh] items-center content2 flex-row gap-10 whitespace-nowrap pl-[10vw]">
        <p
          id="textoId"
          className="Tittle split2 font-black text-black whitespace-nowrap text-[25vh] uppercase"
        >
          Creando experiencias intuitivas
        </p>
      </div>
    </section>
  );
}
