import gsap from "gsap";
import { useEffect } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

export default function About() {
  useEffect(() => {
    // gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

    const Primary = document.getElementById("AboutId");
    const splitTypes = document.querySelectorAll(".split");

    // Dividir el contenido de cada elemento en caracteres
    splitTypes.forEach((element) => {
      const text = element.textContent;
      element.innerHTML = ""; // Limpiar el contenido

      // Crear un span para cada carácter
      text.split("").forEach((char) => {
        const span = document.createElement("span");
        span.textContent = char; // Asignar el carácter al span
        element.appendChild(span); // Agregar el span al elemento
      });
    });

    const splitChars = document.querySelectorAll(".split span"); // Obtener todos los spans

    // Pin para alargar el contenedor
    gsap.to(Primary, {
      ease: "none",
      scrollTrigger: {
        trigger: Primary,
        start: "top top",
        pin: true,
        end: () => `+=2500`,
      },
    });

    // Animación del Título
    gsap.fromTo(
      ".about-title",
      {
        opacity: 0,
        yPercent: 100,
      },
      {
        opacity: 1,
        yPercent: 0,
        duration: 1,
        scrollTrigger: {
          trigger: Primary,
          start: "top top",
          end: "+=0 center",
          // markers: 1, // Descomenta para depurar
          scrub: 2,
        },
      }
    );

    // Animación del Contenido
    gsap.fromTo(
      ".about-content",
      {
        opacity: 0,
        y: 100,
      },
      {
        opacity: 1,
        y: 0,
        duration: 1,
        scrollTrigger: {
          trigger: ".about-title",
          start: "300 center",
          end: "+=0 center",
          // markers: 1, // Descomenta para depurar
          scrub: 2,
        },
      }
    );

    // Animación de los caracteres
    gsap.from(splitChars, {
      scrollTrigger: {
        trigger: ".about-title",
        start: "700 center",
        end: "+=1000 center",
        // markers: true, // Descomenta para depurar
        scrub: 1,
      },
      opacity: 0.2,
      stagger: 0.05, // Ajustar el stagger para animación fluida
    });

    // SCroll to
    gsap.to(window, {
      scrollTrigger: {
        trigger: ".about-title",
        start: "2000 center",
        end: "+=0 center",
        scrub: 1,
        // markers: true,
        onLeave: () =>
          gsap.to(window, { duration: 1, scrollTo: "#seccionParrafo" }), // Desplaza al inicio de la animación
      },
    });
  }, []);

  return (
    <div
      id="AboutId"
      className="about-us--container relative h-screen w-[99vw]"
    >
      <div className="about-us flex flex-row gap-[6rem]">
        <div className="about-title w-[40%] pl-[10vw] h-screen flex items-center sticky top-0">
          <p className="text-[5rem] md:text-[4rem] font-black leading-[1] text-black Tittle text-end w-full uppercase">
            Juntos,
            <br />
            extendemos
            <br />
            los limites
            <br />
            de la realidad
          </p>
        </div>
        <div className="about-content w-[60%] pr-[10vw] flex flex-col justify-center items-center">
          <p className="split text-[2.5rem] md:text-[1.75rem] subTittle font-[400] w-full">
            Nos enfocamos en mejorar la usabilidad de tu proyecto web mediante
            un diseño centrado en el usuario. Sabemos que una buena experiencia
            es clave para el éxito de cualquier página, por eso trabajamos en
            cada aspecto para que los visitantes puedan navegar de manera fácil
            y cumplir sus objetivos sin complicaciones.
          </p>
          <p className="split text-[2.5rem] md:text-[1.75rem] subTittle font-[400] w-full">
            Nos enfocamos en mejorar la usabilidad de tu proyecto web mediante
            un diseño centrado en el usuario. Sabemos que una buena experiencia
            es clave para el éxito de cualquier página, por eso trabajamos en
            cada aspecto para que los visitantes puedan navegar de manera fácil
            y cumplir sus objetivos sin complicaciones.
          </p>
        </div>
      </div>
    </div>
  );
}
