
// import LoaderChars from "./Components/HeroSection/LoaderChars.jsx";

import Hero from "./Components/HeroSection/Hero.jsx";
import Banner from "./Components/BannerSection/Banner.jsx";
import About from "./Components/AboutSection/About.jsx";
import Encabezado from "./Components/HeroSection/Encabezado.jsx";
import Background from "./Components/BackGround/Background.jsx";
import Services from "./Components/ServiceSection/Services.jsx";
import Contact from "./Components/ContactSection/Contact.jsx";
import Mouse from "./Components/HeroSection/Mouse.jsx";

export default function Home() {
  return (
    <main className="main-container">
      <Mouse />
      
      <Background/>

      <Encabezado/>
      
      <Hero />

      <About />

      <Banner />

      <Services />

      {/* <Contact /> */}
    </main>
  );
}
