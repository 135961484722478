import React, { useEffect, useRef } from "react";
import * as THREE from "three";

export default function Background() {
  // Ajustes Generales
  const SizeBalls = 1;
  const numberBalls = 10;

  const mountRef = useRef(null);
  const spheres = []; // Array para almacenar las esferas

  useEffect(() => {
    // Crear escena, cámara y renderizador
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );
    const renderer = new THREE.WebGLRenderer({ alpha: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    mountRef.current.appendChild(renderer.domElement);

    // Añadir un fondo blanco
    scene.background = new THREE.Color(0xffffff);

    // Crear múltiples esferas de colores
    const createSphere = (x) => {
      const geometry = new THREE.SphereGeometry(SizeBalls, 32, 32);

      // Colores en formato hexadecimal
      const colors = [
        "#00ff00", // Verde Primario
        "#60ff60", // Verde -1
        "#bfffbf", // Verde -2
      ];

      // Elegir un color aleatorio del array y convertirlo a THREE.Color
      const colorIndex = Math.floor(Math.random() * colors.length);
      const material = new THREE.MeshBasicMaterial({ color: new THREE.Color(colors[colorIndex]) });

      const sphere = new THREE.Mesh(geometry, material);
      sphere.position.set(x, Math.random() * 2 - 1, Math.random() * 2 - 1); // Posición Y y Z aleatorias

      // Velocidades aleatorias para los ejes X, Y y Z
      sphere.userData.velocity = {
        x: (Math.random() - 0.5) * 0.02, // Velocidad aleatoria para X
        y: (Math.random() - 0.5) * 0.02, // Velocidad aleatoria para Y
        z: (Math.random() - 0.5) * 0.02, // Velocidad aleatoria para Z
      };
      scene.add(sphere);
      spheres.push(sphere);
    };

    // Crear esferas en posiciones aleatorias
    for (let i = 0; i < numberBalls; i++) {
      createSphere((Math.random() - 0.5) * 10); // Posiciones X aleatorias
    }

    camera.position.z = 5;

    // Animación
    const animate = () => {
      requestAnimationFrame(animate);

      spheres.forEach((sphere) => {
        // Mover las esferas aleatoriamente en X, Y y Z
        sphere.position.x += sphere.userData.velocity.x;
        sphere.position.y += sphere.userData.velocity.y;
        sphere.position.z += sphere.userData.velocity.z;

        // Cambiar dirección si alcanza los límites en X
        if (sphere.position.x > 5 || sphere.position.x < -5) {
          sphere.userData.velocity.x = -sphere.userData.velocity.x; // Cambiar dirección en X
        }

        // Cambiar dirección si alcanza los límites en Y
        if (sphere.position.y > 2 || sphere.position.y < -2) {
          sphere.userData.velocity.y = -sphere.userData.velocity.y; // Cambiar dirección en Y
        }

        // Cambiar dirección si alcanza los límites en Z
        if (sphere.position.z > 5 || sphere.position.z < -5) {
          sphere.userData.velocity.z = -sphere.userData.velocity.z; // Cambiar dirección en Z
        }
      });

      renderer.render(scene, camera);
    };

    animate();

    // Limpiar el montador al desmontar el componente
    return () => {
      mountRef.current.removeChild(renderer.domElement);
      renderer.dispose();
    };
  }, []);

  return (
    <div className="h-screen w-screen fixed top-0 left-0 blur-2xl" ref={mountRef} />
  );
}
