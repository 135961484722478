import { useEffect, useState } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
// Imágenes
import imgStack from "../../assets/Images/Logo Stack.svg";
import LetterS from "../../assets/Images/Letter/S.svg";
import LetterT from "../../assets/Images/Letter/T.svg";
import LetterA from "../../assets/Images/Letter/A.svg";
import LetterC from "../../assets/Images/Letter/C.svg";
import LetterK from "../../assets/Images/Letter/K.svg";
import imgsigno from "../../assets/Images/aterisco.svg";

gsap.registerPlugin(ScrollTrigger);

export default function Splash() {
  useEffect(() => {
    const section = document.getElementById("LogoId");

    // Pin para alargar el contenedor
    gsap.to(section, {
      ease: "none",
      scrollTrigger: {
        trigger: section,
        start: "center center",
        pin: true,
        end: () => `+=2600`,
      },
    });

    const letters = document.querySelectorAll(".letter");

    const timeline = gsap.timeline({ delay: 1 });

    gsap.set(".asterisco", {
      opacity: 0,
    });
    gsap.fromTo(
      ".slogan",
      { opacity: 0 }, // Estado inicial
      {
        opacity: 1, // Estado final
        duration: 1, // Duración de la animación
        delay: 2, // Espera 2 segundos antes de comenzar la animación (opcional)
      }
    );

    function asteriscoGSAP() {
      gsap.fromTo(
        ".asterisco",
        {
          scale: 0,
          opacity: 0,
        },
        {
          scale: 1,
          opacity: 1,
          duration: 1,
          ease: "elastic.out(1, 0.75)", // Proporciona un efecto que sobrepasa el valor final antes de estabilizarse
        }
      );
    }
    // Aparicion de SVG uno por uno
    timeline.fromTo(
      letters,
      { opacity: 0, y: 100 }, // Comienzan ocultas y desplazadas hacia abajo
      {
        opacity: 1,
        y: 0,
        duration: 0.5,
        stagger: 0.05,
        ease: "back.out(1.7)",
        onComplete: asteriscoGSAP,
      }
    );

    // Animaciones de salir del "STACK"
    gsap.to(".imgLogo", {
      ease: "power4.out",
      xPercent: -300,
      scrollTrigger: {
        trigger: ".animacion",
        start: "400 top",
        end: "+=100 top",
        scrub: 2,
      },
    });

    // Animacion de Salidad de Slogan
    gsap.to(".slogan", {
      ease: "power4.out",
      opacity: 0,
      scrollTrigger: {
        trigger: ".animacion",
        start: "400 top",
        end: "+=100 top",
        scrub: 2,
      },
    });

    // Animaciones de salir del "*"
    gsap.to(".asterisco", {
      ease: "none",
      xPercent: -220,
      scrollTrigger: {
        trigger: ".animacion",
        start: "400 top",
        end: "+=100 top",
        scrub: 2,
      },
    });

    // Esconder el Asterisco
    gsap.to("#imgLogo2", {
      opacity: 0,
      ease: "none",
      scrollTrigger: {
        trigger: ".animacion",
        start: "1900 top",
        end: "+=0 top",
        scrub: 0.5,
        // markers: true,
      },
    });

    // Cambio de color del fondo
    gsap.to("#LogoId", {
      backgroundColor: "rgba(255, 255, 255, 0)", // color de fondo transparente
      duration: 1, // Duración de la animación
      scrollTrigger: {
        trigger: ".animacion", // El elemento que activa la animación
        start: "1900 top",
        end: "+=400 top", // Cuando se alcanza el final del elemento
        scrub: 1,
        // markers: true,
        onLeave: () => gsap.to(window, { duration: 1, scrollTo: "#AboutId" }), // Desplaza al inicio de la animación
      },
    });

    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);

  return (
    <section
      id="LogoId"
      className="bg-black h-screen flex justify-center relative select-none"
    >
      <div className="animacion size-full flex justify-center items-center">
        <div
          id="contLogo"
          className="flex flex-row items-end gap-4 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
        >
          <div className="imgLogo flex flex-row items-end gap-2">
            <img className=" fill-white letter" src={LetterS} alt="" />
            <img className=" fill-white letter" src={LetterT} alt="" />
            <img className=" fill-white letter" src={LetterA} alt="" />
            <img className=" fill-white letter" src={LetterC} alt="" />
            <img className=" fill-white letter" src={LetterK} alt="" />
          </div>

          <div className="asterisco flex flex-row items-center">
            <img id="imgLogo2" src={imgsigno} alt="" />
          </div>

          <p className="slogan font-black text-[2rem] text-white absolute right-0 bottom-0 translate-y-full">
            tech studio
          </p>
        </div>
      </div>
    </section>
  );
}
